<template>
	<div>
        <shutter-panel ref="shutterPanel" />
    </div>
</template>

<script type="text/javascript">
	import Shutter from "@/mixins/Shutter.js"
	import ShutterSanitaire from "@/mixins/shutters-manager/Sanitaire.js"

	export default {
		name: 'SanitaireList',
		mixins: [Shutter, ShutterSanitaire],
	    props: ['horse_id'],
		data () {
			return {
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.setupHorseSanitaire(this.horse_id)
			},
		},

		components: {
			
		}
	}

</script>